import React from 'react';

import Image from '../../components/Image/Image';
import TitleUnderlineParagraph from '../../components/TitleUnderlineParagraph/TitleUnderlineParagraph';
import * as styles from './Investments.module.scss';
import IInvestments from './Investments.type';

const Investments = (props: IInvestments) => {
    return (
        <section className={styles.section}>
            <div className="container">
                <ul className={styles.list}>
                    {props.list.map((item) => {
                        return (
                            <div key={item.name} className={styles.investment}>
                                <Image
                                    {...item.image}
                                    className={styles.image}
                                    sizes="(min-width: 768px) 567px, 100vw"
                                />
                                <TitleUnderlineParagraph
                                    heading={item.name}
                                    headingLevel="h2"
                                    text={item.description}
                                    textSize="medium"
                                    className={styles.content}
                                />
                            </div>
                        );
                    })}
                </ul>
            </div>
        </section>
    );
};

export default Investments;
